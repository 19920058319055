// Core
import { useTranslation } from "next-i18next";
import cx from "classnames";
import React, { useMemo } from "react";

// Components
import { Trans } from "next-i18next";
import { Image } from "components/ui/Image";
import { Title } from "components/ui/Typography/Title";
import { Text } from "components/ui/Typography/Text";
import { Icon } from "components/ui/Icon";
import { Col } from "components/ui/Col";
import { Row } from "components/ui/Row";
import { Grid } from "components/ui/Grid";
import { Container } from "components/common/Container";
import { Link } from "components/common/Link";

// Domains
import { useUserCountry } from "bus/user/hooks/useUserCountry";

// Utils
import { introProfitability } from "data/intro";
import { rowGutters } from "utils/constants";
import { getIntroProfitabilityGoCorePDFLink } from "components/common/IntroProfitability/utils/common";
import st from "./Styles.module.less";

export const IntroProfitability = () => {
  const { userCountry } = useUserCountry();
  const { t } = useTranslation(["intro"]);
  const { md = true, lg = true } = Grid.useBreakpoint();

  const isMobile = !md && !lg;
  const isTablet = !lg && !isMobile;
  const isDesktop = !isTablet && !isMobile;

  const getClassnames = (className: string): string => {
    return cx(st[className], {
      [st.mobile]: isMobile,
      [st.tablet]: isTablet,
    });
  };

  const contentOffset = (isDesktop && 8) || (isTablet && 12) || 0;

  const titleJSX = t(introProfitability.title);
  const subtitleJSX = t(introProfitability.subtitle);

  const optionsJSX = useMemo(() => {
    return introProfitability.options.map(({ title, icon, description, fullWidth }) => {
      const itemWidth = fullWidth
        ? {
            span: 24,
          }
        : {
            lg: 12,
            sm: 24,
          };

      return (
        <Col key={title} {...itemWidth}>
          <Row gutter={rowGutters} className={getClassnames("option")}>
            <Col>
              <Icon name={icon} className={getClassnames("icon")} />
            </Col>
            <Col>
              <div className={getClassnames("option-title")}>
                <Title size="18" tag="h5" font="second">
                  {t(title)}
                </Title>
              </div>
              <Text size="14" color="gray-700">
                <Trans
                  i18nKey={description}
                  components={{
                    "go-core-link": <Link href={getIntroProfitabilityGoCorePDFLink(userCountry)} />,
                  }}
                />
              </Text>
            </Col>
          </Row>
        </Col>
      );
    });
  }, [isMobile, isTablet, t]);

  return (
    <section className={getClassnames("profitability")}>
      <div className={getClassnames("image-container")}>
        <Image
          src={introProfitability.img.src}
          alt={t(introProfitability.img.alt)}
          placeholder="blur"
          layout="fill"
          objectFit="cover"
        />
      </div>
      <Container>
        <Row gutter={rowGutters}>
          <Col offset={contentOffset} className={getClassnames("content")}>
            {isDesktop && (
              <>
                <div className={getClassnames("title")}>
                  <Title size="36" tag="h2" font="second" fontWeight="700">
                    {titleJSX}
                  </Title>
                </div>
                <div className={getClassnames("subtitle")}>
                  <Title tag="h5" size="16" color="gray-700">
                    {subtitleJSX}
                  </Title>
                </div>
              </>
            )}
            {isTablet && (
              <>
                <div className={getClassnames("title")}>
                  <Title size="30" tag="h2" font="second" fontWeight="700">
                    {titleJSX}
                  </Title>
                </div>
                <div className={getClassnames("subtitle")}>
                  <Title tag="h5" size="16" color="gray-700">
                    {subtitleJSX}
                  </Title>
                </div>
              </>
            )}
            {isMobile && (
              <>
                <div className={getClassnames("title")}>
                  <Title
                    size="18"
                    tag="h2"
                    font="second"
                    fontWeight="700"
                    alignmentHorizontal="center"
                  >
                    {titleJSX}
                  </Title>
                </div>
                <div className={getClassnames("subtitle")}>
                  <Title tag="h5" alignmentHorizontal="center" size="14" color="gray-700">
                    {subtitleJSX}
                  </Title>
                </div>
              </>
            )}
            <Row gutter={rowGutters}>{optionsJSX}</Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
