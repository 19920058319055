// Core
import { useTranslation } from "next-i18next";
import cx from "classnames";
import React, { useMemo } from "react";

// Components
import { Image } from "components/ui/Image";
import { Title } from "components/ui/Typography/Title";
import { Text } from "components/ui/Typography/Text";
import { Col, Grid, Row } from "antd";
import { Icon } from "components/ui/Icon";
import { Container } from "components/common/Container";

// Utils
import st from "./Styles.module.less";
import { introSimplicity } from "data/intro";
import { rowGutters } from "utils/constants";

export const IntroSimplicity = () => {
  const { t } = useTranslation(["intro"]);
  const { md = true, lg = true } = Grid.useBreakpoint();

  const isMobile = !md && !lg;
  const isTablet = !lg && !isMobile;
  const isDesktop = !isTablet && !isMobile;

  const getClassnames = (className: string): string => {
    return cx(st[className], {
      [st.mobile]: isMobile,
      [st.tablet]: isTablet,
    });
  };

  const titleJSX = t(introSimplicity.title);
  const subtitleJSX = t(introSimplicity.subtitle);

  const optionsJSX = useMemo(() => {
    return introSimplicity.options.map(({ title, icon, description }) => {
      return (
        <Col key={title} lg={12} sm={24}>
          <Row gutter={rowGutters} className={getClassnames("option")}>
            <Col>
              <Icon name={icon} className={getClassnames("icon")} />
            </Col>
            <Col>
              <div className={getClassnames("option-title")}>
                <Title size="18" tag="h5" font="second">
                  {t(title)}
                </Title>
              </div>
              <Text size="14" color="gray-700">
                {t(description)}
              </Text>
            </Col>
          </Row>
        </Col>
      );
    });
  }, [isMobile, isTablet, t]);

  return (
    <section className={getClassnames("simplicity")}>
      <Container>
        <Row gutter={rowGutters}>
          <Col lg={16} md={12} xs={24} sm={24} className={getClassnames("content-container")}>
            <div className={st.content}>
              {isDesktop && (
                <>
                  <div className={getClassnames("title")}>
                    <Title size="36" tag="h2" font="second" fontWeight="700">
                      {titleJSX}
                    </Title>
                  </div>
                  <div className={getClassnames("subtitle")}>
                    <Title tag="h5" size="16" color="gray-700">
                      {subtitleJSX}
                    </Title>
                  </div>
                </>
              )}
              {isTablet && (
                <>
                  <div className={getClassnames("title")}>
                    <Title size="30" tag="h2" font="second" fontWeight="700">
                      {titleJSX}
                    </Title>
                  </div>
                  <div className={getClassnames("subtitle")}>
                    <Title tag="h5" size="16" color="gray-700">
                      {subtitleJSX}
                    </Title>
                  </div>
                </>
              )}
              {isMobile && (
                <>
                  <div className={getClassnames("title")}>
                    <Title
                      size="18"
                      tag="h2"
                      font="second"
                      fontWeight="700"
                      alignmentHorizontal="center"
                    >
                      {titleJSX}
                    </Title>
                  </div>
                  <div className={getClassnames("subtitle")}>
                    <Title tag="h5" alignmentHorizontal="center" size="14" color="gray-700">
                      {subtitleJSX}
                    </Title>
                  </div>
                </>
              )}
              <Row gutter={rowGutters} className={getClassnames("options")}>
                {optionsJSX}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={getClassnames("image-container")}>
        <Image
          src={introSimplicity.img.src}
          alt={t(introSimplicity.img.alt)}
          placeholder="blur"
          layout="fill"
          objectFit="cover"
        />
      </div>
    </section>
  );
};
