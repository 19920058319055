// Definitions
import type { UserCountryCodesKeys } from "bus/user/models";
import { UserCountryCodesEnum } from "bus/user/models";

export const getIntroProfitabilityGoCorePDFLink = (country?: UserCountryCodesKeys) => {
  const countryCode = country || UserCountryCodesEnum.en;

  const fileList: Record<UserCountryCodesKeys, string> = {
    [UserCountryCodesEnum.en]:
      "https://scdn.autodoc.pro/gocore_brochure/en/Brochure%20for%20AUTODOC%20PRO.pdf",
    [UserCountryCodesEnum.fr]:
      "https://scdn.autodoc.pro/gocore_brochure/fr/Brochure%20for%20AUTODOC%20PRO.pdf",
    [UserCountryCodesEnum.de]:
      "https://scdn.autodoc.pro/gocore_brochure/de/Brochure%20for%20AUTODOC%20PRO.pdf",
    [UserCountryCodesEnum.at]:
      "https://scdn.autodoc.pro/gocore_brochure/de/Brochure%20for%20AUTODOC%20PRO.pdf",
    [UserCountryCodesEnum.nl]:
      "https://scdn.autodoc.pro/gocore_brochure/nl/Brochure%20for%20AUTODOC%20PRO.pdf",
  };

  return fileList[countryCode] ? fileList[countryCode] : fileList[UserCountryCodesEnum.en];
};
